@import 'src/styles/variables';

.primaryButton {
  max-width: 500px;
  align-self: center;
}

.errorMessage {
  text-align: center;
  font-weight: $semiBoldFontWeight;
}

.errorContainer {
  color: $red;
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 12px;

  .contactLink {
    color: $red;
    text-decoration: underline;
    font-weight: $semiBoldFontWeight;
    cursor: pointer;
  }
}
