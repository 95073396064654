@import 'src/styles/mixins';
@import 'src/styles/variables';

.inputContainer {
  position: relative;

  .input + button {
    right: 8px;
    height: 48px;

    &::after {
      background-color: $primaryColor;
    }

    @include sm {
      right: 16px;
    }
  }
}

.label {
  @include inputLabel;
}

.input {
  @include input;
  color: $black100;
}

.input::-webkit-date-and-time-value {
  text-align: left !important;
}

.customInputContainer::before {
  content: attr(data-placeholder);
  color: $black40;
}

.disabled {
  color: $black60;
}

.disableNativeMobile {
  opacity: 0.5;
}

.monthYearContainer {
  @include verticalGap(8px);
  flex-direction: row;

  .monthPicker {
    flex: 7;
    position: relative;
  }

  .yearPicker {
    flex: 3;
    position: relative;
  }
}
