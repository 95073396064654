@import 'src/styles/variables';
@import 'src/styles/mixins';

.input {
  @include input;

  &::before {
    content: attr(data-placeholder);
    color: $black40;
  }
}

.inputColor {
  color: $black40;
}

.inputFilled {
  color: $black100;
}

.input::-webkit-date-and-time-value {
  text-align: left !important;
}
